/** @module @yext/components-layout */

/**
 * Controls an element and a toggle to expand and collapse it
 */
export class Accordion {
  /**
   * @param {string} scope CSS Selector
   * @param {Object} [args={}]
   * @param {HTMLElement} [args.toggleBtn] Button which toggles the accordion's state
   * @param {HTMLElement} [args.collapsibleE]l Element which opens & closes
   * @param {number} [args.active_bpgte] Accordion js only applies at px values >=
   * @param {number} [args.active_bplte] Accordion js only applies at px values <=
   * @param {string} [args.active_mediaQuery] Accordion js only applies when the media query
   *   matches. Overrides active_bplte and active_bpgte
   * @param {boolean} [args.defaultCollapsed] default state of the accordion
   */
  constructor(scope, args={}) {
    if (!scope) {
      console.warn('Error: Accordion not initialized - Usage: new Accordion(scope: css Selector, {args}: object)')
      return;
    }

    this.el = scope;

    // [arg.key]: [assign to 'this'] = [default value]
    ({
      toggleBtn: this.toggleBtn = this.el.querySelector('.js-accordion-btn'),
      collapsibleEl: this.collapsibleEl = this.el.querySelector('.js-accordion-collapsible-content'),
      active_bpgte: this.active_bpgte = 0,
      active_bplte: this.active_bplte = null,
      active_mediaQuery: this.active_mediaQuery = null,
      defaultCollapsed: this.defaultCollapsed = () => true,
    } = args);

    if (this.toggleBtn && this.collapsibleEl) {
      // Add a listener for changing breakpoints
      if (this.active_mediaQuery == null) {
        const conditions = [];
        if (this.active_bpgte != null) conditions.push(`(min-width: ${this.active_bpgte}px)`);
        if (this.active_bplte != null) conditions.push(`(max-width: ${this.active_bplte}px)`);
        this.active_mediaQuery = conditions.join(' or ');
      }

      this.breakpointMatcher = window.matchMedia(this.active_mediaQuery);
      this.breakpointMatcher.addListener(() => this.checkAndSetInitialState());

      // Check the classList for the initial state, and do that
      this.checkAndSetInitialState();

      // Clicking the toggle button should expand/collapse
      this.bindToggleBtnHandler();
    } else {
      console.warn('Error: Accordion not initialized - Required HTML elements not ' +
                   'found. Expected elements with selector \'.js-accordion-btn\' ' +
                   'and \'.js-accordion-collapsible-content, or custom selectors ' +
                   'passed as args.');
    }
  }

  /**
   * Whether the Accordion js should be active or not, depending on the current width of the screen
   * @returns {boolean}
   */
  isWithinBreakpoints() {
    return this.breakpointMatcher.matches;
  }


  /**
   * Check if the accordion is expanded
   * @returns {boolean} Whether the accordion is expanded
   */
  isExpanded() {
    const oldDisplay = this.collapsibleEl.style.display;
    // Set "display: block" in case its currently "display: none"
    //  before checking the height
    this.collapsibleEl.style.display = 'block';
    const visibleHeight = this.collapsibleEl.offsetHeight;
    const totalHeight = this.collapsibleEl.scrollHeight;
    // Revert to old "display: ?" value
    this.collapsibleEl.style.display = oldDisplay;
    return Math.abs(visibleHeight - totalHeight) < 2; //sometimes in android & IE11, these values differ by 1 when expanded. On other browser's, they're equal.
  }

  /**
   * Sets the initial state of the accordion based on the arg `this.defaultCollapsed`
   */
  checkAndSetInitialState() {
    const isActive = this.isWithinBreakpoints();
    const isCollapsed = isActive && this.defaultCollapsed();

    this.toggleBtn.disabled = !isActive;

    if (isCollapsed) {
      this.collapse(false);
    } else {
      this.expand(false);
    }
  }

  /**
   * When the button is clicked, toggle the accordion
   */
  bindToggleBtnHandler() {
    this.toggleBtn.addEventListener('click', e => {
      if (this.isWithinBreakpoints()) {
        if (this.el.getAttribute('aria-expanded') == 'true') {
          this.collapse();
        } else {
          this.expand();
        }
      }
    });
  }

  /**
   * Expand the accordion
   * @param {boolean} [animate=true] Whether to smoothly transition to expanded
   */
  expand(animate = true) {
    if (animate && this.isExpanded()) { return; } // Do nothing if the content is already expanded

    // The .is-expanded class is updated first in both transitions, so attach the
    //  button animation or whatever else is supposed to happen synchronously to this
    this.el.classList.add('is-expanded');
    this.el.setAttribute('aria-expanded', true); // aria-expanded has styles attached
    this.collapsibleEl.setAttribute('aria-hidden', false);

    if (animate) {
      requestAnimationFrame(() => {
        // Need to get the height dynamically here so the expand/collapse is smooth on all breakpoints
        this.collapsibleEl.style.height = this.collapsibleEl.scrollHeight + 'px';
      });

      // After the transition remove the inline height so the element can
      //  resize itself responsively
      const afterExpand = (e) => {
        if (!e.propertyName === 'height') { return; }
        this.collapsibleEl.removeEventListener('transitionend', afterExpand);
        this.collapsibleEl.style.height = '';
      }

      this.collapsibleEl.addEventListener('transitionend', afterExpand);
    } else {
      this.collapsibleEl.style.height = '';
    }
  }

  /**
   * Collapse the accordion
   * @param {boolean} [animate=true] Whether to smoothly transition to collapsed
   */
  collapse(animate = true) {
    if (animate && !this.isExpanded()) { return; } // Do nothing if the content isn't already expanded

    this.el.classList.remove('is-expanded');

    const updateAriaProperties = () => {
      this.el.setAttribute('aria-expanded', false); // has display: none attached for WCAG
      this.collapsibleEl.setAttribute('aria-hidden', true);
    };

    if (animate) {
      requestAnimationFrame(() => {
        // explicitly set the element's height so we can transition
        //  (can't transition out of height: auto)
        this.collapsibleEl.style.height = this.collapsibleEl.scrollHeight + 'px';

        // On the next frame, start the transition
        requestAnimationFrame(() => {
          this.collapsibleEl.style.height = '0';
        });
      });

      const afterCollapse = (e) => {
        if (!e.propertyName === 'height') { return; }
        this.collapsibleEl.removeEventListener('transitionend', afterCollapse);
        updateAriaProperties();
      };

      this.collapsibleEl.addEventListener('transitionend', afterCollapse);
    } else {
      this.collapsibleEl.style.height = '0';
      updateAriaProperties();
    }
  }
}
