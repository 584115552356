import gotime from 'gotime/gotime.js';
import { missing } from './utils.js';

export const sameDay = (startdate, enddate) => {
  let startDateObject = new Date(secToMillisecond(startdate));
  let endDateObject = new Date(secToMillisecond(enddate));
  if (startDateObject.getUTCFullYear() !== endDateObject.getUTCFullYear()) return false;
  if (startDateObject.getUTCMonth() !== endDateObject.getUTCMonth()) return false;
  if (startDateObject.getUTCDate() !== endDateObject.getUTCDate()) return false;
  return true;
}

export const sameMonth = (startdate, enddate) => {
  let startDateObject = new Date(secToMillisecond(startdate));
  let endDateObject = new Date(secToMillisecond(enddate));
  return startDateObject.getUTCFullYear() === endDateObject.getUTCFullYear() && startDateObject.getUTCMonth() === endDateObject.getUTCMonth();
}

export const sameYear = (startdate, enddate) => {
  let startDateObject = new Date(secToMillisecond(startdate));
  let endDateObject = new Date(secToMillisecond(enddate));
  return startDateObject.getUTCFullYear() === endDateObject.getUTCFullYear();
}

export const ltimef = (locale, format, timestamp) => {
  // TODO Implement ltimef
  missing("ltimef");
}

export const ltimestampf = (locale, format, timestamp) => {
  // TODO Implement ltimestampf
  missing("ltimestampf");
}

// @format - Hour and minute format in Go time format syntax, e.g. '3:04'
// @time - Hour and minute encode into a number as hour*100 + minute.  For example, 3:04PM would be 304.
export const timef = (format, time) => {
  let date = new Date(2006, 1, 1, time/100, time%100);
  return timestampf(format, date.getTime());
};

// @format - Format in Go time format syntax, e.g. 'Mon Jan 2 15:04:05 -0700 MST 2006'
export const timestampf = (format, timestamp) => {
  return gotime.formatTimestamp(secToMillisecond(timestamp), format);
};

const secToMillisecond = (timestamp) => {
  return timestamp * 1000;
};
