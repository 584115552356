import { MonitoringInit } from 'js/components/Monitoring/Monitoring.js';
import { Polyfills } from 'js/components/Polyfills/Polyfills.js';
import { AccessibilityChecks } from 'js/components/Util/Accessibility.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { Debug } from 'js/components/Util/Debug.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { RunIfVisible } from 'js/components/RunIfVisible/index.js';
import 'script-loader!node_modules/svg4everybody/dist/svg4everybody.min.js';
import { AnalyticsDebugger } from 'ts/components/AnalyticsDebugger/AnalyticsDebugger.ts';
import { Accordion } from 'js/common/modules/Accordion.js';

export class Global {
  static init() {
    Polyfills.init();
    MonitoringInit();

    OnReady(() => {
      const thisYear = new Date().getFullYear();
      const dateSpans = document.querySelectorAll('.c-copy-date');
      for (const dateSpan of dateSpans) {
        dateSpan.innerHTML = `&copy; ${thisYear}`;
      }

      const accordionEl = document.querySelector('.Footer-localeAccordion');

      if (accordionEl) {
        new Accordion(accordionEl, {
          defaultCollapsed: () => true,
        });
      }

      ImageObjectFit();
      svg4everybody();

      if (Yext.isStaging ||
          window.location.href.includes('yextpages') ||
          window.location.href.includes('localhost')) {
        AccessibilityChecks.checkAltTags();
      }

      RunIfVisible.lazyLoadImages();

      // Provide a global callback so the client's scripts can choose when to enable Yext Analytics
      window.enableYextAnalytics = async () => {
        window.yextAnalyticsEnabled = true;
        window.enableYextAnalytics = () => {};

        const analytics = require('js/components/YextAnalytics/Analytics.js');

        if (Debug.isEnabled()) {
          window.Debugger = new AnalyticsDebugger();
        }
      };

      if (window.yextAnalyticsEnabled || Debug.isEnabled()) {
        window.enableYextAnalytics();
      }
    });
  }
}
